import React from "react";
import pick from "lodash/pick";
import * as Yup from "yup";
import capitalize from "lodash/capitalize";
import Select from "react-select";
import { Formik } from "formik";
import Error from "../../../form/Error";
import Label from "../../../form/Label";
import Input from "../../../form/Input";
import FormCheckbox from "../../../form/FormCheckbox";
import CheckboxWithInput from "../../../form/CheckboxWithInput";
import SecondaryButton from "../../../SecondaryButton";
import SaveButton from "../../../form/SaveButton";
import BowelSoundsCheckboxes from "./BowelSoundsCheckboxes";
import {
  digestiveBowelsFields,
  digestiveBowelSoundsFields,
  digestiveBladderFields,
  digestiveAbdomenFields,
} from "../../../../utils/assessmentUtils";
import {
  CONTINUE,
  EXIT,
  digestiveAssessmentMappings,
} from "../../../../constants";
import { verifyChecked, checkOneMessage } from "./basicAssessmentValidations";

const catheterLocationOptions = [
  { value: "urethra", label: "Urethra" },
  { value: "suprapubic", label: "Suprapubic" },
];

const catheterTypeOptions = [
  { value: "indwelling", label: "Indwelling" },
  { value: "intermittent", label: "Intermittent" },
  { value: "external", label: "External" },
];

const catheterSizeOptions = [
  { value: "10fr", label: "10fr" },
  { value: "12fr", label: "12fr" },
  { value: "14fr", label: "14fr" },
  { value: "16fr", label: "16fr" },
  { value: "18fr", label: "18fr" },
];

const bowelsCheckboxFields = digestiveBowelsFields.filter((x) => {
  return !x.endsWith("_bm");
});

const bladderCheckboxFields = digestiveBladderFields.slice(0, 8);

const validationSchema = Yup.object().shape({
  bowels: Yup.object()
    .shape({
      bowels_continence: Yup.string(),
      bowels_incontinent: Yup.boolean(),
      bowels_constipated: Yup.boolean(),
      bowels_loose: Yup.boolean(),
      bowels_diarrhea: Yup.boolean(),
      bowels_tarry_stools: Yup.boolean(),
      bowels_brown: Yup.boolean(),
      bowels_ostomy: Yup.boolean(),
    })
    .test("bowels-answered-test", checkOneMessage, verifyChecked("bowels")),
  bowels_last_bm: Yup.date().required("You must enter a date for Last BM"),
  bowel_sounds: Yup.object().shape({
    bowel_sounds_ruq_audible: Yup.string().required(
      "You must indicate present / absent for RUQ"
    ),
    bowel_sounds_ruq_activity: Yup.string().when("bowel_sounds_ruq_audible", {
      is: "present",
      then: Yup.string().required("You must indicate activity level for RUQ"),
      otherwise: null,
    }),
    bowel_sounds_rlq_audible: Yup.string().required(
      "You must indicate present / absent for RLQ"
    ),
    bowel_sounds_rlq_activity: Yup.string().when("bowel_sounds_rlq_audible", {
      is: "present",
      then: Yup.string().required("You must indicate activity level for RLQ"),
      otherwise: null,
    }),
    bowel_sounds_luq_audible: Yup.string().required(
      "You must indicate present / absent for LUQ"
    ),
    bowel_sounds_luq_activity: Yup.string().when("bowel_sounds_luq_audible", {
      is: "present",
      then: Yup.string().required("You must indicate activity level for LUQ"),
      otherwise: null,
    }),
    bowel_sounds_llq_audible: Yup.string().required(
      "You must indicate present / absent for LLQ"
    ),
    bowel_sounds_llq_activity: Yup.string().when("bowel_sounds_llq_audible", {
      is: "present",
      then: Yup.string().required("You must indicate activity level for LLQ"),
      otherwise: null,
    }),
  }),
  bladder: Yup.object()
    .shape({
      bladder_continence: Yup.string(),
      bladder_distended: Yup.boolean(),
      bladder_odor: Yup.boolean(),
      bladder_dribbles: Yup.boolean(),
      bladder_pain: Yup.boolean(),
      bladder_oliguria: Yup.boolean(),
      bladder_spasms: Yup.boolean(),
      bladder_urostomy: Yup.boolean(),
      bladder_other: Yup.boolean(),
      bladder_other_value: Yup.string().when("bladder_other", {
        is: true,
        then: Yup.string()
          .required("Please type a description in the 'Other' box")
          .max(60),
        otherwise: null,
      }),
    })
    .test("bladder-answered-test", checkOneMessage, verifyChecked("bladder")),
  bladder_catheter: Yup.object().shape({
    bladder_catheter_care: Yup.boolean(),
    bladder_catheter_location: Yup.string().when("bladder_catheter_care", {
      is: true,
      then: Yup.string().required("You must enter a catheter location"),
      otherwise: null,
    }),
    bladder_catheter_type: Yup.string().when("bladder_catheter_care", {
      is: true,
      then: Yup.string().required("You must enter a catheter type"),
      otherwise: null,
    }),
    bladder_catheter_size: Yup.string().when("bladder_catheter_care", {
      is: true,
      then: Yup.string().required("You must enter a catheter size"),
      otherwise: null,
    }),
    bladder_catheter_insertion_date: Yup.string(),
    bladder_catheter_change_frequency: Yup.boolean(),
    bladder_catheter_change_frequency_value: Yup.string().when(
      "bladder_catheter_change_frequency",
      {
        is: true,
        then: Yup.string()
          .required("You must enter a catheter change frequency")
          .max(60),
        otherwise: null,
      }
    ),
  }),
  abdomen: Yup.object()
    .shape({
      abdomen_size: Yup.string(),
      abdomen_firmness: Yup.string(),
      abdomen_mass: Yup.boolean(),
      abdomen_mass_value: Yup.string().when("abdomen_mass", {
        is: true,
        then: Yup.string().required("You must enter a Mass value"),
        otherwise: null,
      }),
      abdomen_feeding_tube: Yup.boolean(),
      abdomen_feeding_tube_value: Yup.string().when("abdomen_feeding_tube", {
        is: true,
        then: Yup.string().required("You must enter a Feeding Tube value"),
        otherwise: null,
      }),
      abdomen_girth: Yup.string(),
    })
    .test("abdomen-answered-test", checkOneMessage, verifyChecked("abdomen")),
});

const digestiveBladderStandardFields = digestiveBladderFields.filter(
  (x) => !x.includes("catheter")
);
const digestiveBladderCatheterFields = digestiveBladderFields.filter((x) =>
  x.includes("catheter")
);

class DigestiveAssessmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.initialValues = {
      bowels: {
        ...pick(props.assessment, digestiveBowelsFields),
      },
      bowels_last_bm: props.assessment ? props.assessment.bowels_last_bm : "",
      bowel_sounds: {
        ...pick(props.assessment, digestiveBowelSoundsFields),
      },
      bladder: {
        ...pick(props.assessment, digestiveBladderStandardFields),
      },
      bladder_catheter: {
        ...pick(props.assessment, digestiveBladderCatheterFields),
      },
      abdomen: {
        ...pick(props.assessment, digestiveAbdomenFields),
      },
    };
    this.formRef = React.createRef();
  }

  _handleSubmit = (values) => {
    console.log("Form: ", values);

    if (!values.bladder.bladder_urostomy) {
      values.bladder.bladder_urostomy_value = "";
    }

    if (!values.bladder.bladder_catheter_change_frequency) {
      values.bladder.bladder_catheter_change_frequency_value = "";
    }

    if (!values.abdomen.abdomen_mass) {
      values.abdomen.abdomen_mass_value = "";
    }

    if (!values.abdomen.abdomen_feeding_tube) {
      values.abdomen.abdomen_feeding_tube_value = "";
    }

    this.props.onSubmit({
      id: this.props.assessment.id,
      ...values.bowels,
      bowels_last_bm: values.bowels_last_bm,
      ...values.bowel_sounds,
      ...values.bladder,
      ...values.bladder_catheter,
      ...values.abdomen,
    });

    if (this.state.submitType === CONTINUE) {
      this.props.onContinue();
    } else {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onSubmit={this._handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldValue,
          submitForm,
          isSubmitting,
          isValidating,
        }) => {
          if (Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
            this.formRef.current.scrollIntoView({ behavior: "smooth" });
          }

          const renderError = (category, key = null) => {
            if (key) {
              return (
                errors[category] &&
                errors[category][key] && (
                  <Error id={`error_${key}`}>{errors[category][key]}</Error>
                )
              );
            } else if (typeof errors[category] === "string") {
              return (
                errors[category] && (
                  <Error id={`error_${category}`}>{errors[category]}</Error>
                )
              );
            }
          };

          const maybeEnableCatheterCare = () => {
            if (!values.bladder_catheter.bladder_catheter_care) {
              setFieldValue("bladder_catheter.bladder_catheter_care", true);
            }
          };

          const clearCatheterValues = () => {
            setFieldValue("bladder_catheter.bladder_catheter_location", "");
            setFieldValue("bladder_catheter.bladder_catheter_type", "");
            setFieldValue("bladder_catheter.bladder_catheter_size", "");
            setFieldValue(
              "bladder_catheter.bladder_catheter_insertion_date",
              ""
            );
            setFieldValue(
              "bladder_catheter.bladder_catheter_change_frequency",
              false
            );
            setFieldValue(
              "bladder_catheter.bladder_catheter_change_frequency_value",
              ""
            );
          };

          return (
            <form
              onSubmit={handleSubmit}
              ref={this.formRef}
              id="digestive-assessment-form"
            >
              <div className="p-6">
                <h4
                  id="bowels-heading"
                  className="text-xl text-gray-800 font-bold mb-6"
                >
                  Bowels (Required)
                </h4>
                <div className="w-full flex flex-row flex-wrap">
                  {["continent", "incontinent"].map((value) => (
                    <FormCheckbox
                      key={value}
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() => {
                        if (values.bowels.bowels_continence === value) {
                          setFieldValue("bowels.bowels_continence", "");
                        } else {
                          setFieldValue("bowels.bowels_continence", value);
                        }
                      }}
                      checked={values.bowels.bowels_continence === value}
                      id={`bowels_continence_${value}_checkbox`}
                    >
                      {capitalize(value)}
                    </FormCheckbox>
                  ))}

                  {bowelsCheckboxFields
                    .filter((x) => !x.endsWith("continence"))
                    .map((key, index) => (
                      <FormCheckbox
                        key={index}
                        className="w-40 mb-4 mr-4 p-2 flex items-center"
                        onClick={() =>
                          setFieldValue(`bowels.${key}`, !values["bowels"][key])
                        }
                        checked={!!values["bowels"][key]}
                        id={`bowels_${key}_checkbox`}
                      >
                        {digestiveAssessmentMappings[key]}
                      </FormCheckbox>
                    ))}
                </div>
                {renderError("bowels")}

                <div className="mt-2 w-1/4">
                  <Label htmlFor="bowels_last_bm">Last BM</Label>
                  <Input
                    name="bowels_last_bm"
                    type="date"
                    value={values.bowels_last_bm}
                    onChange={handleChange}
                    id="bowels_last_bm"
                  />
                  {renderError("bowels_last_bm")}
                </div>
                <div className="mt-6 mb-12">
                  <table id="bowel-sounds-table" className="w-full bg-white">
                    <thead>
                      <tr>
                        <td className="w-1/2 p-4 text-left align-top">
                          Bowel Sounds
                        </td>
                        <td className="p-4 text-left align-top">Present</td>
                        <td className="w-36 p-4 text-left align-top border-r-2 border-gray-400">
                          Absent
                        </td>
                        <td className="p-4 pl-6 text-left align-top">
                          Hyperactive
                        </td>
                        <td className="p-4 text-left align-top">Hypoactive</td>
                        <td className="p-4 text-left align-top">Normal</td>
                      </tr>
                    </thead>
                    <tbody>
                      <BowelSoundsCheckboxes
                        audible={values.bowel_sounds.bowel_sounds_ruq_audible}
                        activity={values.bowel_sounds.bowel_sounds_ruq_activity}
                        onAudibleChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_ruq_audible",
                            val
                          )
                        }
                        onActivityChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_ruq_activity",
                            val
                          )
                        }
                        name="Right Upper Quadrant (RUQ)"
                        index={0}
                      />
                      {renderError("bowel_sounds", "bowel_sounds_ruq_audible")}
                      {renderError("bowel_sounds", "bowel_sounds_ruq_activity")}

                      <BowelSoundsCheckboxes
                        audible={values.bowel_sounds.bowel_sounds_rlq_audible}
                        activity={values.bowel_sounds.bowel_sounds_rlq_activity}
                        onAudibleChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_rlq_audible",
                            val
                          )
                        }
                        onActivityChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_rlq_activity",
                            val
                          )
                        }
                        name="Right Lower Quadrant (RLQ)"
                        index={1}
                      />
                      {renderError("bowel_sounds", "bowel_sounds_rlq_audible")}
                      {renderError("bowel_sounds", "bowel_sounds_rlq_activity")}

                      <BowelSoundsCheckboxes
                        audible={values.bowel_sounds.bowel_sounds_luq_audible}
                        activity={values.bowel_sounds.bowel_sounds_luq_activity}
                        onAudibleChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_luq_audible",
                            val
                          )
                        }
                        onActivityChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_luq_activity",
                            val
                          )
                        }
                        name="Left Upper Quadrant (LUQ)"
                        index={2}
                      />
                      {renderError("bowel_sounds", "bowel_sounds_luq_audible")}
                      {renderError("bowel_sounds", "bowel_sounds_luq_activity")}

                      <BowelSoundsCheckboxes
                        audible={values.bowel_sounds.bowel_sounds_llq_audible}
                        activity={values.bowel_sounds.bowel_sounds_llq_activity}
                        onAudibleChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_llq_audible",
                            val
                          )
                        }
                        onActivityChange={(val) =>
                          setFieldValue(
                            "bowel_sounds.bowel_sounds_llq_activity",
                            val
                          )
                        }
                        name="Left Lower Quadrant (LLQ)"
                        index={3}
                      />
                    </tbody>
                  </table>
                  {renderError("bowel_sounds", "bowel_sounds_llq_audible")}
                  {renderError("bowel_sounds", "bowel_sounds_llq_activity")}
                </div>
                <hr />
                <div className="my-12">
                  <h4
                    id="bladder-heading"
                    className="block text-gray-800 text-xl font-bold mb-6"
                  >
                    Bladder (Required)
                  </h4>
                  <div className="w-full flex flex-row flex-wrap">
                    {["continent", "incontinent"].map((value) => (
                      <FormCheckbox
                        key={value}
                        className="w-40 mb-4 mr-4 p-2 flex items-center"
                        onClick={() => {
                          if (values.bladder.bladder_continence === value) {
                            setFieldValue("bladder.bladder_continence", "");
                          } else {
                            setFieldValue("bladder.bladder_continence", value);
                          }
                        }}
                        checked={values.bladder.bladder_continence === value}
                        id={`bladder_continence_${value}_checkbox`}
                      >
                        {capitalize(value)}
                      </FormCheckbox>
                    ))}

                    {bladderCheckboxFields
                      .filter((x) => !x.endsWith("continence"))
                      .map((field, i) => (
                        <FormCheckbox
                          key={i}
                          className="w-40 mb-4 mr-4 p-2 rounded flex items-center"
                          onClick={() =>
                            setFieldValue(
                              `bladder.${field}`,
                              !values["bladder"][field]
                            )
                          }
                          checked={!!values["bladder"][field]}
                          id={`bladder_${field}_checkbox`}
                        >
                          {digestiveAssessmentMappings[field]}
                        </FormCheckbox>
                      ))}
                  </div>

                  <CheckboxWithInput
                    className="mr-4 p-2 rounded flex items-start mt-2"
                    onClick={() => {
                      setFieldValue(
                        "bladder.bladder_other",
                        !values.bladder.bladder_other
                      );
                      if (values.bladder.bladder_other_value) {
                        setFieldValue("bladder.bladder_other_value", "");
                      }
                    }}
                    checked={!!values.bladder.bladder_other}
                    onInputChange={handleChange}
                    checkboxTitle="Other"
                    inputName="bladder.bladder_other_value"
                    inputValue={values.bladder.bladder_other_value}
                    inputPlaceholder="Please clarify..."
                  />
                  {renderError("bladder", "bladder_other_value")}
                  {renderError("bladder")}

                  <div className="mt-12 w-full flex flex-row items-end">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 rounded flex items-center"
                      onClick={() => {
                        if (values.bladder_catheter.bladder_catheter_care) {
                          clearCatheterValues();
                        }
                        setFieldValue(
                          "bladder_catheter.bladder_catheter_care",
                          !values.bladder_catheter.bladder_catheter_care
                        );
                      }}
                      checked={!!values.bladder_catheter.bladder_catheter_care}
                    >
                      {digestiveAssessmentMappings["bladder_catheter_care"]}
                    </FormCheckbox>

                    <div className="w-40 mr-4 mb-4">
                      <Label htmlFor="bladder_catheter.bladder_catheter_location">
                        Catheter Location
                      </Label>
                      <div onClick={maybeEnableCatheterCare}>
                        <Select
                          id="bladder_catheter_location"
                          name="bladder_catheter.bladder_catheter_location"
                          value={
                            catheterLocationOptions.find(
                              (opt) =>
                                opt.value ===
                                values.bladder_catheter
                                  .bladder_catheter_location
                            ) || ""
                          }
                          options={catheterLocationOptions}
                          placeholder="Select one..."
                          className="react-select-container--clinical"
                          classNamePrefix="react-select"
                          onChange={(option, action) => {
                            setFieldValue(
                              "bladder_catheter.bladder_catheter_location",
                              option.value
                            );
                          }}
                          components={{ IndicatorSeparator: null }}
                          isDisabled={
                            !values.bladder_catheter.bladder_catheter_care
                          }
                        />
                      </div>
                    </div>
                    <div className="w-40 mr-4 mb-4">
                      <Label htmlFor="bladder_catheter.bladder_catheter_type">
                        Catheter Type
                      </Label>
                      <div onClick={maybeEnableCatheterCare}>
                        <Select
                          id="bladder_catheter_type"
                          name="bladder_catheter.bladder_catheter_type"
                          value={
                            catheterTypeOptions.find(
                              (opt) =>
                                opt.value ===
                                values.bladder_catheter.bladder_catheter_type
                            ) || ""
                          }
                          options={catheterTypeOptions}
                          placeholder="Select one..."
                          className="react-select-container--clinical"
                          classNamePrefix="react-select"
                          onChange={(option, action) => {
                            setFieldValue(
                              "bladder_catheter.bladder_catheter_type",
                              option.value
                            );
                          }}
                          components={{ IndicatorSeparator: null }}
                          isDisabled={
                            !values.bladder_catheter.bladder_catheter_care
                          }
                        />
                      </div>
                    </div>
                    <div className="w-40 mr-4 mb-4">
                      <Label htmlFor="bladder_catheter.bladder_catheter_size">
                        Catheter Size
                      </Label>
                      <div onClick={maybeEnableCatheterCare}>
                        <Select
                          id="bladder_catheter_size"
                          name="bladder_catheter.bladder_catheter_size"
                          value={
                            catheterSizeOptions.find(
                              (opt) =>
                                opt.value ===
                                values.bladder_catheter.bladder_catheter_size
                            ) || ""
                          }
                          options={catheterSizeOptions}
                          placeholder="Select one..."
                          className="react-select-container--clinical"
                          classNamePrefix="react-select"
                          onClick={maybeEnableCatheterCare}
                          onChange={(option, action) => {
                            setFieldValue(
                              "bladder_catheter.bladder_catheter_size",
                              option.value
                            );
                          }}
                          components={{ IndicatorSeparator: null }}
                          isDisabled={
                            !values.bladder_catheter.bladder_catheter_care
                          }
                        />
                      </div>
                    </div>
                    <div className="w-48 mr-4 mb-4">
                      <Label htmlFor="bladder_catheter.bladder_catheter_insertion_date">
                        Date of Insertion
                      </Label>
                      <Input
                        name="bladder_catheter.bladder_catheter_insertion_date"
                        type="date"
                        value={
                          values.bladder_catheter
                            .bladder_catheter_insertion_date
                        }
                        onChange={handleChange}
                        disabled={
                          !values.bladder_catheter.bladder_catheter_care
                        }
                      />
                    </div>
                  </div>
                  {renderError("bladder_catheter", "bladder_catheter_type")}
                  {renderError("bladder_catheter", "bladder_catheter_size")}
                  {renderError("bladder_catheter", "bladder_catheter_location")}
                  <div>
                    <CheckboxWithInput
                      className="mr-4 p-2 rounded flex items-start mt-2"
                      onClick={() => {
                        setFieldValue(
                          "bladder_catheter.bladder_catheter_change_frequency",
                          !values.bladder_catheter
                            .bladder_catheter_change_frequency
                        );
                        if (
                          values.bladder_catheter
                            .bladder_catheter_change_frequency_value
                        ) {
                          setFieldValue(
                            "bladder_catheter.bladder_catheter_change_frequency_value",
                            ""
                          );
                        }
                      }}
                      checked={
                        !!values.bladder_catheter
                          .bladder_catheter_change_frequency
                      }
                      onInputChange={handleChange}
                      checkboxTitle="Frequency of Change"
                      inputName="bladder_catheter.bladder_catheter_change_frequency_value"
                      inputValue={
                        values.bladder_catheter
                          .bladder_catheter_change_frequency_value
                      }
                      inputPlaceholder="Please clarify..."
                    />
                    {renderError(
                      "bladder_catheter",
                      "bladder_catheter_change_frequency_value"
                    )}
                  </div>
                </div>

                <hr />
                <div className="my-12">
                  <h4
                    id="abdomen-heading"
                    className="block text-gray-800 text-xl font-bold mb-6"
                  >
                    Abdomen (Required)
                  </h4>
                  <div className="flex flex-row">
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("abdomen.abdomen_size", "flat")
                      }
                      checked={values.abdomen.abdomen_size === "flat"}
                      id="abdomen_size_flat_checkbox"
                    >
                      Flat
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("abdomen.abdomen_size", "distended")
                      }
                      checked={values.abdomen.abdomen_size === "distended"}
                      id="abdomen_size_distended_checkbox"
                    >
                      Distended
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("abdomen.abdomen_size", "round")
                      }
                      checked={values.abdomen.abdomen_size === "round"}
                      id="abdomen_size_round_checkbox"
                    >
                      Round
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("abdomen.abdomen_firmness", "soft")
                      }
                      checked={values.abdomen.abdomen_firmness === "soft"}
                      id="abdomen_firmness_soft_checkbox"
                    >
                      Soft
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("abdomen.abdomen_firmness", "hard")
                      }
                      checked={values.abdomen.abdomen_firmness === "hard"}
                      id="abdomen_firmness_hard_checkbox"
                    >
                      Hard
                    </FormCheckbox>
                    <FormCheckbox
                      className="w-40 mb-4 mr-4 p-2 flex items-center"
                      onClick={() =>
                        setFieldValue("abdomen.abdomen_firmness", "tender")
                      }
                      checked={values.abdomen.abdomen_firmness === "tender"}
                      id="abdomen_firmness_tender_checkbox"
                    >
                      Tender
                    </FormCheckbox>
                  </div>
                  <div className="flex flex-row">
                    <CheckboxWithInput
                      className="mr-4 p-2 rounded flex items-start mt-2"
                      onClick={() => {
                        setFieldValue(
                          "abdomen.abdomen_mass",
                          !values.abdomen.abdomen_mass
                        );
                        if (values.abdomen.abdomen_mass_value) {
                          setFieldValue("abdomen.abdomen_mass_value", "");
                        }
                      }}
                      checked={!!values.abdomen.abdomen_mass}
                      onInputChange={handleChange}
                      checkboxTitle="Mass"
                      inputName="abdomen.abdomen_mass_value"
                      inputValue={values.abdomen.abdomen_mass_value}
                      inputPlaceholder="Please clarify..."
                      id="abdoment_mass_value"
                    />
                    <CheckboxWithInput
                      className="mr-4 p-2 rounded flex items-start mt-2"
                      onClick={() => {
                        setFieldValue(
                          "abdomen.abdomen_feeding_tube",
                          !values.abdomen.abdomen_feeding_tube
                        );
                        if (values.abdomen.abdomen_feeding_tube_value) {
                          setFieldValue(
                            "abdomen.abdomen_feeding_tube_value",
                            ""
                          );
                        }
                      }}
                      checked={!!values.abdomen.abdomen_feeding_tube}
                      onInputChange={handleChange}
                      checkboxTitle="Feeding Tube"
                      inputName="abdomen.abdomen_feeding_tube_value"
                      inputValue={values.abdomen.abdomen_feeding_tube_value}
                      inputPlaceholder="Please clarify..."
                      id="abdomen_feeding_tube"
                    />
                  </div>
                  {renderError("abdomen")}
                  {renderError("abdomen", "abdomen_mass_value")}
                  {renderError("abdomen", "abdomen_feeding_tube_value")}
                  <div className="mt-4 w-40">
                    <Label htmlFor="abdomen.abdomen_girth">
                      Girth (inches)
                    </Label>
                    <Input
                      name="abdomen.abdomen_girth"
                      type="number"
                      value={values.abdomen.abdomen_girth}
                      onChange={handleChange}
                      id="abdomen_girth"
                    />
                    {renderError("abdomen", "abdomen_girth")}
                  </div>
                </div>
              </div>

              <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
                <SecondaryButton
                  id="cancel-button"
                  className="inline-block mr-4"
                  type="button"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </SecondaryButton>
                <SaveButton
                  id="save-exit"
                  className="mr-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ submitType: EXIT }, () => submitForm());
                  }}
                >
                  Save and Exit
                </SaveButton>
                <SaveButton
                  id="save-continue"
                  className="mr-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ submitType: CONTINUE }, () => submitForm());
                  }}
                >
                  Save and Continue
                </SaveButton>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default DigestiveAssessmentForm;
