import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import classnames from "classnames";
import { renderMidnight } from "../utils/timeUtils";

const valid = (measurement) => {
  return (
    measurement !== null && measurement !== "" && measurement !== undefined
  );
};

const RefusedValue = () => (
  <span className="font-normal text-gray-600">Refused</span>
);

const VitalTableRow = ({
  index,
  readOnly,
  vital,
  onClick,
  shiftEndsAtMidnight,
  shiftType,
}) => (
  <tr
    className={classnames(
      "align-top",
      { "cursor-pointer": !readOnly, "cursor-default": readOnly },
      { "bg-gray-100": index % 2 === 1 }
    )}
    onClick={() => (readOnly ? null : onClick(index))}
  >
    <td className="p-4 align-middle">
      {shiftEndsAtMidnight
        ? renderMidnight(vital.recordedAt)
        : moment(vital.recordedAt).format("H:mm")}
    </td>


    <td className="p-4 align-middle">
      {vital.clientRefused &&
      !valid(vital.bloodPressureSystolic) &&
      !valid(vital.bloodPressureDiastolic) ? (
        <RefusedValue />
      ) : (
        <span>
          {vital.bloodPressureSystolic}/{vital.bloodPressureDiastolic}
        </span>
      )}
    </td>
    <td className="p-4 align-middle">
      {vital.clientRefused && !valid(vital.pulse) ? (
        <RefusedValue />
      ) : (
        <span>{vital.pulse}</span>
      )}
    </td>
    <td className="p-4 align-middle">
      {vital.clientRefused && !valid(vital.respirationRate) ? (
        <RefusedValue />
      ) : (
        <span>{vital.respirationRate}</span>
      )}
    </td>
    <td className="p-4 align-middle">
      {vital.clientRefused && !valid(vital.temperature) ? (
        <RefusedValue />
      ) : (
        <span>{vital.temperature}&deg;F</span>
      )}
    </td>
    <td className="p-4 align-middle">
      {vital.clientRefused && !valid(vital.oxygenSaturationLevel) ? (
        <RefusedValue />
      ) : (
        <span>{vital.oxygenSaturationLevel}%</span>
      )}
    </td>
    <td className="p-4 align-middle">
      {vital.clientRefused && !valid(vital.weight) ? (
        <RefusedValue />
      ) : (
        <span>{vital.weight}</span>
      )}
    </td>
    {!readOnly && (
      <td className="p-4 text-right align-middle">
        <button
          id="vitals-edit-button"
          className="bg-blue-600 text-gray-100 p-3 px-4 rounded-full inline-block"
          onClick={() => onClick(index)}
        >
          <i className="fas fa-pencil inline-block mr-1" /> Edit
        </button>
      </td>
    )}
  </tr>
);

VitalTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  vital: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  shiftEndsAtMidnight: PropTypes.bool,
};

VitalTableRow.defaultProps = {
  readOnly: false,
  shiftEndsAtMidnight: false,
};

export default VitalTableRow;
